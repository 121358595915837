<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">后台管理系统</div>
      <el-form
          :model="param"
          :rules="rules"
          v-loading="loading"
          ref="login"
          label-width="0px"
          class="ms-content">
        <el-form-item prop="手机号">
          <el-input v-model="param.phone" placeholder="手机号">
            <template #prepend>
              <el-button icon="el-icon-user"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              type="password"
              placeholder="密码"
              v-model="param.password"
              @keyup.enter="submitForm()"
          >
            <template #prepend>
              <el-button icon="el-icon-lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" @click="submitForm()">登录</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login, getMemu } from '../api/login'

export default {
  data() {
    return {
      param: {
        phone: "",
        password: ""
      },
      rules: {
        phone: [
          { required: true, message: "请输入用户名", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" }
        ]
      },
      loading: false
    };
  },
  created() {
    this.$store.commit("clearTags");
  },
  methods: {
    submitForm() {
      this.$refs.login.validate(valid => {
        if (!valid) return
        this.loading = true
        login(this.param).then(data => {
          if (data === false) {
            this.loading = false
            return
          }
          this.$message.success("登录成功")
          localStorage.setItem("ms_username", this.param.phone)
          localStorage.setItem("token", data.token)
          localStorage.setItem("overTime", Date.parse(new Date()))
          // 获取菜单
          getMemu(0).then(data => {
            if (data == "") {
              this.$router.push({name:'403'});
              return
            }
            localStorage.setItem("memu", JSON.stringify(data))
            this.$router.push({name:data[0].path+'table'})
          }).catch(() => {})

          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      });
    }
  }
};
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/login-bg.jpg);
  background-size: 100%;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
</style>
